import { ActivatedRouteSnapshot, Params, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';

export abstract class SyncGuard {
  subs = new SubSink();

  canActivate(
    next: ActivatedRouteSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.subs.sink = this.sync(next.params).subscribe();

    return true;
  }

  canDeactivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    this.deactivateCallback();
    this.subs.unsubscribe();

    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected deactivateCallback() {}

  protected abstract sync(params: Params): Observable<any>;
}
